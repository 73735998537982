import axios from "axios";

const instance = axios.create({
    baseURL: 'https://churchplusv3coreapi.azurewebsites.net/',
});

// Intercept request to include the token
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken"); // Assuming you store the token in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Set the Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;