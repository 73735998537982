<script setup>
import { ref, computed } from "vue";
import axios from "../public/baseurl/backendbaseurl";
// import axios from "@/gateway/backendapi";
import { ElMessage } from "element-plus";
// import { useRoute } from "vue-router";
import router from "../../../router";
import store from "../../../store/store";
// import { useStore } from "vuex";

const email = ref("");
// const store = useStore();
const password = ref("");
const tenantId = computed(() => store.getters["ecommerce/getId"]);
const sessionTennantId = ref(sessionStorage.getItem("tenantId"));
const loading = ref(false);
// const route = useRoute();

const handleLogin = async () => {
  loading.value = true;
  let payload = {
    email: email.value,
    password: password.value,
    tenantID: tenantId.value ? tenantId.value : sessionTennantId.value,
  };
  try {
    const { data } = await axios.post("/mobile/v1/Account/SignIn", payload);
    console.log(data, "handlelogin");
    loading.value = false;
    if (data.status) {
      ElMessage({
        type: "success",
        showClose: true,
        message: data.response,
        duration: 10000,
      });
      localStorage.setItem("loggedIn", true);
      // Set loggedIn in Vuex store
      store.dispatch("ecommerce/setLogin", true);
      localStorage.setItem("authToken", data.returnObject.token);
      localStorage.setItem("userId", data.returnObject.userId);
      router.push(`/store/home?id=${data.returnObject.tenantID}`);
    } else {
      ElMessage({
        type: "error",
        showClose: true,
        message: data.response,
        duration: 10000,
      });
    }
  } catch (error) {
    console.log(error);
    loading.value = false;
  }
};
</script>
<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-9">
        <div class="col-md-12 d-flex text-center justify-content-center">
          <div class="s-36 text-dak">Welcome Back, Login</div>
        </div>
        <div class="col-md-12 d-flex justify-content-center mb-3">
          <div class="s-20 fw-400">Shop for your Favourites</div>
        </div>
        <div class="card bg-gray-500 p-4">
          <div class="card-body">
            <div>
              <div class="form-group">
                <label class="fw-500 s-20 text-dak" for="email">Email</label>
                <el-input
                  type="email"
                  class="w-100"
                  v-model="email"
                  placeholder="Email Address"
                  required
                />
              </div>
              <div class="form-group">
                <label class="fw-500 s-20 text-dak" for="password">Password</label>
                <el-input
                  type="password"
                  class="w-100"
                  v-model="password"
                  placeholder="Password"
                  required
                />
              </div>
              <!-- <div class="form-group text-right">
                <a href="#" class="fw-500 text-dak">Forgot password?</a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-7 mt-5 mb-3">
              <el-button
                round
                @click="handleLogin"
                color="#FF5906"
                class="text-white w-100 py-4"
                >Login</el-button
              >
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex justify-content-center">
          <div class="text-center mt-3 mb-4">
            <router-link
              :to="`/store/createaccount?id=${tenantId ? tenantId : sessionTennantId}`"
              class="fw-500 text-decoration-none text-dak"
              >New here? Create an account</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
